import { all, and, or, equal, any, notEqual } from '@/services/filtering'
import { getEnum } from "@/features/schemas/services/schemaProvider"

export function buildFilter(tasksFilter, ids = null) {
    const filters = [];

    addIdFilter(filters, tasksFilter.buckets, "bucket", "byte");
    addIdFilter(filters, tasksFilter.clientIds, "clientId");
    addIdFilter(filters, tasksFilter.projectIds, "projectId");
    addIdFilter(filters, tasksFilter.taskTypes, "taskType", "byte");
    addIdFilter(filters, ids, "id");
    addTagIdFilter(filters, tasksFilter.tagIds);

    addPersonFilters(filters, tasksFilter.personIds.filter(id => id != null));
    addUnassignedFilter(filters, tasksFilter.personIds.some(id => id == null));

    if (!tasksFilter.projectIds?.length) {
        addOnHoldFilter(filters)
    }

    const filter = and(filters);
    return filter;
}

function addOnHoldFilter(filters) {
    const onHoldFilter = equal("project.isOnHold", false, "bool");
    filters.push(onHoldFilter);
}

function addTagIdFilter(filters, ids) {
    const idFilters = ids.map(e => any("tags", equal("id", e, "int")));
    if(idFilters == null || idFilters.length === 0) {
        return;
    }
    filters.push(or(idFilters));
}

function addIdFilter(filters, ids, property, type) {
    const idFilters = ids?.map(id => equal(property, id, type));
    if(idFilters == null || idFilters.length === 0) {
        return;
    }
    filters.push(or(idFilters));
}

function addPersonFilters(filters, ids) {
    const personFilters = ids.map(id => buildPersonFilter(id));
    if(personFilters == null || personFilters.length === 0) {
        return;
    }
    filters.push(or(personFilters));
}

function getBucketFilters() {
    const bucket = getEnum("bucket");
    const buildBucketFilter = value => equal("bucket", value, "byte?");

    const isReviewerBucket = buildBucketFilter(bucket.review);

    const isDeveloperBucket = or([
        buildBucketFilter(bucket.estimating),
        buildBucketFilter(bucket.approved),
        buildBucketFilter(bucket.inProgress)
    ]);

    const isOtherBucket = or([
        buildBucketFilter(bucket.backlog),
        buildBucketFilter(bucket.complete),
        buildBucketFilter(bucket.ongoing)
    ]);

    return { isReviewerBucket, isDeveloperBucket, isOtherBucket };
}

function buildPersonFilter(id) {
    // is a developer and is in estimating, approved or in progress
    // is a reviewer and is in pull request
    // is anyone and is in a custom bucket, backlog or complete.

    const {
        isReviewerBucket,
        isOtherBucket
    } = getBucketFilters();

    const taskRole = getEnum("taskRole");
    const buildRoleFilter = value => equal("taskRole", value, "byte");
    const isDeveloper = buildRoleFilter(taskRole.developer);
    const isReviewer = buildRoleFilter(taskRole.reviewer);

    const isPerson = equal("personId", id);

    return or([
        and([any("taskPeople", and([isPerson, isDeveloper]))]),
        and([isReviewerBucket, any("taskPeople", and([isPerson, isReviewer]))]),
        and([isOtherBucket, any("taskPeople", isPerson)])
    ]);
}

function addUnassignedFilter(filters, showUnassigned) {
    if(!showUnassigned) {
        return;
    }
    const unassignedFilter = buildUnassignedFilter();
    filters.push(unassignedFilter);
}

function buildUnassignedFilter() {
    // is in estimating, approved or in progress and has no developers
    // is in pull request and has no reviewers
    const {
        isReviewerBucket,
        isDeveloperBucket
    } = getBucketFilters();

    const taskRole = getEnum("taskRole");
    const buildNotRoleFilter = value => notEqual("taskRole", value, "byte");
    const isNotDeveloper = buildNotRoleFilter(taskRole.developer);
    const isNotReviewer = buildNotRoleFilter(taskRole.reviewer);

    const unassignedFilter = or([
        and([isDeveloperBucket, all("taskPeople", isNotDeveloper)]),
        and([isReviewerBucket, all("taskPeople", isNotReviewer)]),
    ]);
    return unassignedFilter;
}
